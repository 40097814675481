<template>
  <div>
    <template v-for="parent in form">
      <template v-if="parent.show">
        <!-- 供应商 -->
        <supplier :disabled="isPreview || is_combination == 1" v-if="parent.fieldName == 'supplier'" :show="parent.show" :key="parent.fieldName" :list="supplier" :spec="spec" :form="form"></supplier>
        <!-- 规格 -->
        <spec :disabled="isPreview" v-else-if="parent.fieldName == 'spec'" :show="parent.show" :key="parent.fieldName" :list="spec" :supplier="supplier"></spec>
        <!-- 组合产品 -->
        <combination :disabled="isPreview" v-else-if="parent.fieldName == 'combination'" :show="parent.show" :key="parent.fieldName" :list="combination"></combination>
        <!-- 编辑器和附件 -->
        <template v-else-if="parent.fieldName == 'gallery' || parent.fieldName == 'content'">
          <lz-form
            :key="parent.fieldName"
            :form="parent.list"
            ref="lzForm"
            :disabled="isPreview"
            :col="1">
          </lz-form>
        </template>
        <!-- 其他 -->
        <lz-card
          v-else
          :title="parent.name"
          :key="parent.fieldName">
          <div slot="right" v-if="isPreview && parent.fieldName == 'base'" data-flex="main:right">
            <el-button type="primary" @click="handlePagePreview">页面预览</el-button>
          </div>
          <lz-form
            @change-select="hadleChangeSelect"
            :disabled="isPreview"
            :form="parent.list"
            ref="lzForm"
            :col="3">
          </lz-form>
          <!-- <div v-if="parent.fieldName == 'base'" data-flex="main:right">
            <el-button-group size="mini" v-if="!isPreview">
              <el-button type="primary" @click="handleSelectAll('area')">全选配送区域</el-button>
              <el-button type="danger" @click="handleSelectAll('area', [])">清空配送区域</el-button>
              <el-button type="primary" @click="handleSelectAll('un_area')">全选不可配送区域</el-button>
              <el-button type="danger" @click="handleSelectAll('un_area', [])">清空不可配送区域</el-button>
            </el-button-group>
          </div> -->
        </lz-card>
      </template>
    </template>
    <lz-sticky v-if="!isPreview">
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
      <el-button type="primary" @click="handleGetApi">接口获取</el-button>
    </lz-sticky>
    <lz-dialog
      title="在线预览"
      :width="400"
      :is-close-btn="false"
      ref="lzDialog">
      <div data-flex="main:center">
        <iframe scrolling="yes" frameborder="0" :src="config.giftUrl +'/details/' + id" style="height: 667px;width: 375px"></iframe>
      </div>
    </lz-dialog>
  </div>
</template>
<script>
import { formGetValue } from '@/utils/form';
import config from '@/config';
import cfg from './config';
import supplier from './supplier.vue';
import spec from './spec.vue';
import combination from './combination';

export default {
  props: {
    isPreview: { // 是否预览模式
      defualt: false,
    },
  },
  components: {
    supplier,
    spec,
    combination,
  },
  data() {
    return {
      config,
      form: JSON.parse(JSON.stringify(cfg.form)),
      info: {},
      supplier: [],
      spec: [],
      combination: [],
      is_combination: '',
      id: '',
    };
  },
  mounted() {
    if (!this.isPreview) this.getData();
  },
  methods: {
    async getData(goodId) {
      const id = this.isPreview ? goodId : this.$route.query.id;
      if (!id) return;
      const params = {
        id,
      };
      this.id = id;
      const response = await this.$baseHttp.get('/goods/detail', { params });
      if (!response) return;
      this.supplier = response.data.supplier;
      this.combination = response.data.combination;
      this.is_combination = response.data.is_combination;
      if (this.is_combination === 1) {
        this.supplier = [];
        response.data.combination.forEach((el) => {
          this.supplier.push(el.supplier);
        });
      }
      this.spec = response.data.spec_num;
      if (response.data.title && !this.isPreview) this.$baseTitle(response.data.title);
      this.form.forEach((parent, index) => {
        this.$nextTick(() => {
          if (this.$refs.lzForm[index] === undefined) return;
          this.$refs.lzForm[index].setValue({
            data: response.data,
          });
        });
        if (parent.fieldName === 'combination') {
          this.$set(parent, 'show', response.data.is_combination === 1);
        }
        // if (parent.fieldName === 'spec' || parent.fieldName === 'supplier') {
        //   this.$set(parent, 'show', response.data.is_combination === 0);
        // }
        if (parent.fieldName === 'spec') {
          this.$set(parent, 'show', response.data.is_combination === 0);
        }
      });
    },
    async handleSubmit() {
      const allError = [];
      let data = {};
      this.form.forEach((parent) => {
        const { params, error } = formGetValue(parent.list);
        allError.push(...error);
        data = Object.assign(params, data);
      });
      if (allError.length > 0) {
        this.$message.error(`${allError[0].name}格式有误`);
        return;
      }
      // 判断区域是否重复
      // const area = data.area ? data.area.split(',') : [];
      // const unArea = data.un_area ? data.un_area.split(',') : [];
      // if (unArea.some((r) => area.includes(r))) {
      //   this.$message.error('存在区域重复');
      //   return;
      // }
      // // 配送区域特殊判断
      // if (data.area === '') delete data.area;
      // if (data.un_area === '') delete data.un_area;
      // Object.keys(data).filter((key) => key.indexOf('area') >= 0).forEach((key) => {
      //   const value = data[key].split(',');
      //   data[key] = [];
      //   value.forEach((r) => {
      //     data[key].push([r]);
      //   });
      // });
      // 这边保存用数组形式所以需要转换下
      Object.keys(data).filter((key) => key.indexOf('_ids') >= 0 && key !== 'gallery_ids').forEach((key) => {
        data[key] = data[key].split(','); // eslint-disable-line
      });
      this.spec.forEach((el) => {
        delete el.htmlButton; // eslint-disable-line
      });
      data.spec_num = this.spec;
      data.supplier = this.supplier;
      data.combination = this.combination;
      const response = await this.$baseHttp.post('/goods/save', data);
      if (!response) return;
      this.$message.success('操作成功');
      if (data.id) {
        this.$baseCloseTag();
        this.$delete(this.$route.query, 'id');
        this.$router.push({
          name: 'goods-list',
          query: this.$route.query,
        });
        return;
      }
      this.form = [];
      setTimeout(() => {
        this.form = JSON.parse(JSON.stringify(cfg.form));
        this.spec = [];
        this.combination = [];
        this.supplier = [];
      });
      // const name = this.$route.name;
      // this.$baseCloseTag();
      // this.$router.push({ name });
    },
    handleSelectAll(fieldName, value = null) {
      const element = this.form[0].list.find((r) => r.fieldName === fieldName);
      if (value) {
        this.$set(element, 'value', value);
        return;
      }
      this.$set(element, 'value', element.options.map((r) => String(r.value)));
    },
    handlePagePreview() {
      window.open(`${config.giftUrl}/details/${this.id}`);
      // this.$refs.lzDialog.dialogVisible = true;
    },
    hadleChangeSelect(element) {
      // console.log(element);
      if (element.fieldName !== 'is_combination') {
        return;
      }
      this.form.filter((r) => ['combination', 'spec', 'supplier'].includes(r.fieldName)).forEach((parent) => {
        if (parent.fieldName === 'combination') {
          this.$set(parent, 'show', element.value === '1');
          return;
        }
        this.$set(parent, 'show', element.value === '0');
      });
    },
    handleGetApi() {
      this.form.forEach((parent, index) => {
        this.$nextTick(() => {
          if (this.$refs.lzForm[index] === undefined) return;
          this.$refs.lzForm[index].resertForm();
        });
      });
    },
  },
};
</script>

const status = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
];

export default {
  search: [
    { name: '名称/型号', type: 'text', fieldName: 'keyword', value: '' },
    {
      name: '品牌',
      type: 'select',
      fieldName: 'brand_id',
      value: '',
      remote: true,
      multiple: true,
      collapseTags: true,
      width: '180px',
      props: {
        url: '/brand/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      fieldName: 'cate_id',
      type: 'treeSelect',
      name: '品类',
      value: [],
      options: [],
      remote: true,
      props: {
        url: '/cate/list',
        value: 'id',
        label: 'name',
        params: { type: 1 },
      },
    },
    {
      name: '组合产品',
      placeholder: '组合产品',
      type: 'select',
      width: '110px',
      fieldName: 'is_combination',
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    // { name: '虚拟产品', type: 'select', fieldName: 'is_virtual', value: '', options: status },
    {
      name: '直客档位',
      type: 'select',
      fieldName: 'price_title_id',
      value: '',
      remote: true,
      props: {
        url: '/price-title/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '分销档位',
      type: 'select',
      fieldName: 'fx_price_id',
      value: '',
      remote: true,
      props: {
        url: '/price-title/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '价格区间',
      type: 'select',
      fieldName: 'price_range_id',
      value: '',
      remote: true,
      props: {
        url: '/price-range/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '场景',
      type: 'select',
      fieldName: 'scene_id',
      value: '',
      remote: true,
      props: {
        url: '/scene/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '用途',
      type: 'select',
      fieldName: 'purpose_id',
      value: '',
      remote: true,
      props: {
        url: '/purpose/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '供应商',
      type: 'select',
      fieldName: 'supplier_id',
      value: [],
      options: [],
      remote: true,
      props: {
        url: '/supplier/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '产品类型',
      placeholder: '产品类型',
      type: 'select',
      width: '110px',
      fieldName: 'productFomate',
      value: '',
      options: [
        { label: '直充产品', value: 'is_charge' },
        { label: '虚拟产品', value: 'is_virtual' },
        { label: '普通产品', value: 'is_normal' },
      ],
    },
    {
      name: '结算方式',
      type: 'select',
      fieldName: 'settlement_type',
      value: '',
      options: [],
      constantDict: 'settlemenType',
    },
    { name: '是否上架', type: 'select', fieldName: 'is_shelves', value: '', options: status },
    { name: '是否断货', type: 'select', fieldName: 'is_out_stock', value: '', options: status },
    { name: '是否停产', type: 'select', fieldName: 'is_stop', value: '', options: status },
    {
      name: '是否一件代发',
      type: 'select',
      fieldName: 'is_shipping',
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    // { name: '库存最小值', type: 'text', fieldName: 'stock_min', value: '', width: '170px' },
    // { name: '库存最大值', type: 'text', fieldName: 'stock_max', value: '', width: '170px' },
    { name: '可发货', type: 'daterange', fieldName: 'delivery_begin_time||delivery_end_time', value: [], width: '300px' },
  ],
  form: [
    {
      name: '基础信息',
      show: true,
      fieldName: 'base',
      list: [
        { fieldName: 'title', type: 'text', name: '产品名称', value: '', required: true },
        { fieldName: 'model', type: 'text', name: '产品型号', value: '', required: true },
        { fieldName: 'market_price', type: 'text', name: '市场价格', value: '', required: true },
        { fieldName: 'jd_price', type: 'text', name: '京东价格', value: '', required: false },
        { fieldName: 'jd_link', type: 'text', name: '链接', value: '', required: false },
        { fieldName: 'recommend_count', type: 'number', name: '推荐星级', value: '', required: false, max: 5 },
        {
          name: '结清方式',
          type: 'select',
          fieldName: 'settlement_type',
          value: '',
          options: [],
          constantDict: 'settlemenType',
          required: false,
        },
        {
          fieldName: 'delivery_province_id||delivery_city_id',
          type: 'cascader',
          name: '发货地址',
          value: [],
          required: false,
          remote: true,
          options: [],
          props: {
            url: '/common/area',
            value: 'value',
            label: 'label',
            params: { level: 2 },
          },
        },
        {
          fieldName: 'delivery_begin_time||delivery_end_time',
          type: 'daterange',
          name: '发货时间',
          value: [],
          required: false,
          width: '50%',
        },
        {
          fieldName: 'area',
          type: 'cascader',
          name: '可配送区域',
          value: [],
          required: false,
          remote: true,
          multiple: true,
          width: '100%',
          options: [],
          props: {
            url: '/common/area',
            value: 'value',
            label: 'label',
            params: { level: 2 },
          },
        },
        // {
        //   fieldName: 'un_area',
        //   type: 'select',
        //   name: '不可配送区域',
        //   value: [],
        //   required: false,
        //   remote: true,
        //   multiple: true,
        //   width: '100%',
        //   props: {
        //     url: '/common/area',
        //     value: 'value',
        //     label: 'label',
        //     params: { level: 1 },
        //   },
        // },
        { name: 'id', type: 'text', fieldName: 'id', value: '', hide: true },
      ],
    },
    {
      name: '产品属性',
      show: true,
      fieldName: 'attrbuite',
      list: [
        {
          name: '品牌',
          type: 'select',
          fieldName: 'brand_id',
          value: [],
          options: [],
          remote: true,
          required: true,
          multiple: false,
          props: {
            url: '/brand/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '价格区间',
          type: 'select',
          fieldName: 'price_range_id',
          value: [],
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/price-range/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '直客档位',
          type: 'select',
          fieldName: 'price_title_id',
          value: [],
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/price-title/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '分销档位',
          type: 'select',
          fieldName: 'fx_price_id',
          value: [],
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/price-title/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'cate_ids',
          type: 'treeSelect',
          name: '品类',
          value: '',
          options: [],
          required: true,
          remote: true,
          width: '100%',
          props: {
            url: '/cate/list',
            value: 'id',
            label: 'name',
            params: { type: 1 },
          },
        },
        {
          name: '应用场景',
          type: 'select',
          fieldName: 'scene_ids',
          value: [],
          options: [],
          remote: true,
          required: true,
          width: '100%',
          multiple: true,
          props: {
            url: '/scene/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '产品用途',
          type: 'select',
          fieldName: 'purpose_ids',
          value: [],
          options: [],
          remote: true,
          required: true,
          width: '100%',
          multiple: true,
          props: {
            url: '/purpose/list',
            value: 'id',
            label: 'name',
          },
        },
      ],
    },
    {
      name: '产品配置',
      show: true,
      fieldName: 'product',
      list: [
        {
          fieldName: 'is_shelves',
          type: 'select',
          name: '是否上架',
          required: true,
          value: '1',
          options: status,
        },
        {
          fieldName: 'is_out_stock',
          type: 'select',
          name: '是否断货',
          required: true,
          value: '0',
          options: status,
        },
        {
          fieldName: 'is_stop',
          type: 'select',
          name: '是否停产',
          required: true,
          value: '0',
          options: status,
        },
        {
          fieldName: 'is_virtual',
          type: 'select',
          name: '是否虚拟产品',
          required: true,
          value: '',
          options: status,
        },
        {
          fieldName: 'status',
          type: 'select',
          name: '是否启用',
          required: true,
          value: '0',
          options: [
            { label: '是', value: 0 },
            { label: '否', value: 1 },
          ],
        },
        {
          name: '是否一件代发',
          type: 'select',
          fieldName: 'is_shipping',
          required: true,
          value: '0',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        {
          name: '是否组合产品',
          type: 'select',
          fieldName: 'is_combination',
          required: true,
          value: '0',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        {
          fieldName: 'is_charge',
          type: 'select',
          name: '是否直充',
          required: true,
          value: '0',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        {
          fieldName: 'particle_index',
          type: 'select',
          name: '粒子显示',
          required: false,
          value: '0',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
        },
        { fieldName: 'charge_memo', type: 'textarea', name: '直充备注', value: '', required: false, width: '100%' },
      ],
    },
    {
      name: '组合产品',
      fieldName: 'combination',
      list: [],
      show: false,
    },
    {
      name: '产品规格',
      fieldName: 'spec',
      list: [],
      show: true,
    },
    {
      name: '供应商',
      fieldName: 'supplier',
      list: [],
      show: true,
    },
    {
      name: '附件',
      fieldName: 'gallery',
      show: true,
      list: [
        {
          fieldName: 'gallery_ids',
          responseName: 'gallery',
          type: 'upload',
          name: '附件',
          value: [],
          multiple: true,
          required: false,
          width: '100%',
        },
      ],
    },
    {
      name: '详细信息',
      show: true,
      fieldName: 'content',
      list: [
        { fieldName: 'content', type: 'editor', name: '详细信息', value: '', required: false, width: '100%' },
      ],
    },
  ],
};

<template>
  <lz-card
    title="供应商">
    <el-button :disabled="disabled" slot="right" type="primary" @click="handleAddSupplier">新增</el-button>
    <el-table
      :data="list"
      :header-cell-style="{backgroundColor: '#f6f8fa'}"
      border>
      <el-table-column label="供应商" width="250px">
        <template slot-scope="scope">
          <el-select
            clearable
            :disabled="disabled"
            filterable
            style="width: 100%;"
            v-model="scope.row.supplier_id">
            <el-option
              v-for="ops in supplierList"
              :label="ops.name"
              :value="ops.id"
              :key="ops.id">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="自备货价格" width="200px">
        <template slot-scope="scope">
          <el-input-number
            :disabled="disabled"
            :min="0"
            @change="handleChaneStockingPrice(scope.row, 'stocking_price')"
            v-model="scope.row.stocking_price"
            clearable
            placeholder="自备货价格"
            style="width: 100%;">
          </el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="是否代发" width="100px">
        <template slot-scope="scope">
          <el-select
            clearable
            :disabled="disabled"
            filterable
            style="width: 100%;"
            v-model="scope.row.is_shipping">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="代发货价格" width="200px">
        <template slot-scope="scope">
          <el-input-number :disabled="disabled" :min="0" v-model="scope.row.shipping_price" clearable placeholder="代发货价格" style="width: 100%;"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="税运" width="200px">
        <template slot-scope="scope">
          <el-select
            clearable
            :disabled="disabled"
            filterable
            style="width: 100%;"
            v-model="scope.row.tax_type">
            <el-option label="含税含运费" :value="1"></el-option>
            <el-option label="含税不含运" :value="2"></el-option>
            <el-option label="含运不含税" :value="3"></el-option>
            <el-option label="不含税运" :value="4"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="规格" width="200px">
        <template slot-scope="scope">
          <el-select
            clearable
            filterable
            :disabled="disabled"
            multiple
            style="width: 100%;"
            :multiple-limit="1"
            v-model="scope.row.spec_ids">
            <el-option
              :label="item.spec_name"
              :value="item.spec_id"
              v-for="(item, index) in spec"
              :key="index">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="一件代发价" width="200px">
        <template slot-scope="scope">
          <el-input-number :disabled="disabled" :min="0" v-model="scope.row.generation_price" clearable placeholder="一件代发价" style="width: 100%;"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="商城价" width="200px">
        <template slot-scope="scope">
          <el-input-number :disabled="disabled" :min="0" v-model="scope.row.mall_price" clearable placeholder="商城价" style="width: 100%;"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80" fixed="right">
        <template slot-scope="scope">
          <el-link
            :disabled="disabled"
            :underline="false"
            @click="list.splice(scope.$index, 1)"
            type="danger">
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
  </lz-card>
</template>
<script>
import BigNumber from 'bignumber.js';

export default {
  props: {
    list: {
      default: () => [],
    },
    spec: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
    form: {
      default: () => [],
    },
  },
  data() {
    return {
      supplierList: [],
    };
  },
  mounted() {
    this.$baseHttp.get('/supplier/list?page_size=1000').then((response) => {
      this.supplierList = response.data.data;
    });
  },
  methods: {
    handleAddSupplier() {
      this.list.push({
        supplier_id: '',
        is_shipping: 1,
        shipping_price: '',
        stocking_price: '',
        tax_type: '',
        mall_price: '',
        spec_ids: [],
        nums: [],
      });
    },
    handleChaneStockingPrice(row, type) {
      const marketPrice = this.form[0].list.find((r) => r.fieldName === 'market_price');
      if (!marketPrice || marketPrice.value === '') {
        this.$set(row, 'mall_price', '');
        this.$message.error('市场价不存在或不能为空');
        return;
      }
      const total = BigNumber(row[type]).times(1.6).toNumber();
      this.$set(row, 'mall_price', total > marketPrice.value ? marketPrice.value : total);
    },
  },
};
</script>

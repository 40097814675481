<template>
  <div>
    <lz-card
      title="产品规格">
      <el-button :disabled="disabled" slot="right" type="primary" @click="handleCommonBtnCLick('add')">新增</el-button>
      <lz-table-list
        :table="table"
        @table-btn-click="handleCommonBtnCLick"
        :list="list">
      </lz-table-list>
    </lz-card>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :width="500"
      title="产品规格"
      ref="lzDialog">
      <lz-form :form="form" :col="1" ref="lzForm" @tree-node-click="handleTreeNodeClick"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      default: [],
    },
    supplier: {
      default: [],
    },
    disabled: {
      default: false,
    },
  },
  watch: {
    list: function change() {
      this.list.forEach((el) => {
        this.$set(el, 'htmlButton', []);
        if (el.is_edit) el.htmlButton.push({ name: '查看', key: 'detail', type: 'primary', disabled: this.disabled });
        if (el.is_del) el.htmlButton.push({ name: '删除', key: 'delete', type: 'danger', disabled: this.disabled });
      });
    },
  },
  data() {
    return {
      index: '',
      form: [
        { fieldName: 'attachment_id', type: 'upload', name: '图片800x800', value: [] },
        {
          fieldName: 'spec_id',
          type: 'treeSelect',
          name: '规格',
          value: '',
          options: [],
          remote: true,
          props: {
            url: '/spec/list',
            value: 'id',
            label: 'name',
            params: { type: 1 },
          },
          required: true,
        },
        { name: '发货次数', type: 'number', fieldName: 'num', value: '', required: true },
        {
          fieldName: 'status',
          type: 'select',
          name: '上下架',
          required: true,
          value: '0',
          options: [
            { label: '是', value: 0 },
            { label: '否', value: 1 },
          ],
        },
        { name: '备注', type: 'text', fieldName: 'memo', value: '' },
      ],
      table: [
        { name: 'id', prop: 'spec_id' },
        { name: '图片', prop: 'pic', type: 'image' },
        { name: '规格名称', prop: 'spec_name' },
        {
          name: '上下架',
          prop: 'status',
          width: '100px',
          type: 'switch',
          activeVlue: 0,
          inactiveValue: 1,
          // switchDisabled: () => true,
        },
        { name: '备注', prop: 'memo' },
        { name: '发货次数', prop: 'num' },
        { name: '操作', prop: 'htmlButton' },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      specName: '',
    };
  },
  methods: {
    async handleCommonBtnCLick(type, element, index) {
      switch (type) {
        case 'detail':
          this.index = index;
          this.handleDialogForm(element);
          break;
        case 'delete': {
          const id = this.list[index].spec_id;
          if (this.$route.query.id) {
            const response = await this.$baseHttp.post('/goods/check-spec-delete', {
              id: this.$route.query.id,
              spec_id: id,
            });
            if (response.data === 1) {
              this.$message.error('该规格已被使用过');
              return;
            }
          }
          this.$baseConfirm({
            content: '删除后,所有已关联规格都会清空',
            callConfirm: async () => {
              this.supplier.forEach((r) => {
                const specIndex = r.spec_ids.findIndex((key) => key === id);
                if (specIndex >= 0) r.spec_ids.splice(specIndex, 1);
              });
              this.list.splice(index, 1);
            },
          });
        }
          break;
        case 'save': {
          const params = {};
          this.form.forEach((element) => {
            params[element.fieldName] = element.value;
            if (element.type === 'upload' && element.value.length > 0) {
              params.attachment_id = element.value[0].id;
              params.pic = element.value[0].pic;
            }
          });
          params.status = Number(params.status);
          params.spec_name = this.specName;
          if (params.spec_id === '' || params.num <= 0) {
            this.$message.error('请输入必填项');
            return;
          }
          if (this.list.find((r) => String(r.spec_id) === String(params.spec_id)) && this.index === '') {
            this.$message.error('已存在规格');
            return;
          }
          if (this.index === '') {
            this.list.push(params);
          } else {
            this.$set(this.list, this.index, params);
          }
          this.$message.success('操作成功');
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.index = '';
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      const data = JSON.parse(JSON.stringify(response || {
        num: 1,
      }));
      this.specName = response ? response.spec_name : '';
      if (data && data.attachment_id) {
        this.$set(data, 'attachment_id', [{
          id: data.attachment_id,
          pic: data.pic,
          file: data.pic,
          ext: 'jpg',
        }]);
      } else {
        this.$set(data, 'attachment_id', []);
      }
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(async () => {
        this.$refs.lzForm.setValue({
          data: data || {},
        });
      });
    },
    handleTreeNodeClick(node) {
      this.specName = node.name;
    },
  },
};
</script>

<template>
  <div v-if="show">
    <lz-card
      title="组合产品">
      <el-button :disabled="disabled" slot="right" type="primary" @click="handleCommonBtnCLick('add')">新增</el-button>
      <lz-table-list
        :table="table"
        @table-btn-click="handleCommonBtnCLick"
        :list="list">
      </lz-table-list>
    </lz-card>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :width="500"
      title="产品规格"
      ref="lzDialog">
      <lz-form :form="form" :col="1" ref="lzForm" @change-select="handleChangeSelect"></lz-form>
    </lz-dialog>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      default: [],
    },
    disabled: {
      default: false,
    },
    show: {
      default: false,
    },
  },
  watch: {
    list: function change() {
      this.list.forEach((el) => {
        this.$set(el, 'htmlButton', [
          { name: '删除', key: 'delete', type: 'danger', disabled: this.disabled },
        ]);
      });
    },
  },
  mounted() {
    this.list.forEach((el) => {
      this.$set(el, 'htmlButton', [
        { name: '删除', key: 'delete', type: 'danger', disabled: this.disabled },
      ]);
    });
  },
  data() {
    return {
      index: '',
      form: [
        {
          fieldName: 'goods_id',
          type: 'select',
          name: '产品名称',
          required: true,
          value: '',
          options: [],
          remoteMethod: (val) => {
            this.$baseHttp.get(`/goods/list?keyword=${val}`).then((response) => {
              const goods = this.form.find((r) => r.fieldName === 'goods_id');
              this.$set(goods, 'options', response.data.data.map((r) => ({
                ...r,
                label: r.title,
                value: r.id,
              })));
            });
          },
        },
        {
          fieldName: 'supplier_id',
          type: 'select',
          name: '供应商',
          required: true,
          value: '',
          options: [],
        },
        {
          fieldName: 'spec_id',
          type: 'select',
          name: '规格',
          required: true,
          value: '',
          options: [],
        },
      ],
      table: [
        { name: '产品id', prop: 'goods_id' },
        { name: '产品名称', prop: 'title' },
        { name: '规格名称', prop: 'spec_name' },
        { name: '供应商', prop: 'supplier_name' },
        { name: '操作', prop: 'htmlButton' },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
    };
  },
  methods: {
    handleCommonBtnCLick(type, element, index) {
      switch (type) {
        case 'delete':
          this.$baseConfirm({
            content: '确定删除',
            callConfirm: async () => {
              this.list.splice(index, 1);
            },
          });
          break;
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          const goods = this.form[0].options.find((r) => String(r.value) === params.goods_id);
          const supplier = this.form[1].options.find((r) => String(r.value) === params.supplier_id);
          const spec = this.form[2].options.find((r) => String(r.value) === params.spec_id);
          const isRepeat = this.list.some((r) => r.goods_id === params.goods_id
            && r.supplier_id === params.supplier_id
            && r.spec_id === params.spec_id);
          if (isRepeat) {
            this.$message.error('存在相同参数');
            return;
          }
          this.list.push({
            ...params,
            spec_name: spec.label,
            supplier_name: supplier.label,
            title: goods.title,
          });
          this.$message.success('操作成功');
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(async () => {
        this.$refs.lzForm.setValue({
          data: response || {},
        });
      });
    },
    handleChangeSelect(element) {
      const ops = element.options.find((r) => String(r.value) === String(element.value));
      if (element.fieldName === 'goods_id') {
        this.form.filter((r) => ['supplier_id', 'spec_id'].includes(r.fieldName)).forEach((el) => {
          this.$set(el, 'value', '');
          this.$set(el, 'options', []);
          if (el.fieldName === 'supplier_id' && ops) {
            this.$set(el, 'options', ops.supplier.map((r) => ({
              ...r,
              label: r.supplier_name,
              value: r.supplier_id,
            })));
          }
        });
      }
      if (element.fieldName === 'supplier_id') {
        this.form.filter((r) => ['spec_id'].includes(r.fieldName)).forEach((el) => {
          this.$set(el, 'value', '');
          this.$set(el, 'options', []);
          if (ops && ops.spec_ids.length > 0) {
            this.$set(el, 'options', ops.spec_ids.map((r, index) => ({
              ...r,
              label: ops.spec_name[index],
              value: r,
            })));
          } else {
            this.$set(el, 'options', [
              { label: '无', value: '0' },
            ]);
          }
        });
      }
    },
  },
};
</script>
